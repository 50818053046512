.footer-blackbg {
	color: white;
	background-color: black;
}

.footer-whitebg {
	color: black;
	background-color: white;
}

.footer-home {
	padding-left: 115px;

	width: 100%;
	display: grid;
	grid-template-columns: 1fr 1fr;
	overscroll-behavior: none;
}

.footer-whitebg a {
	color: black;
}

.footer-blackbg a {
	color: white;
}

.footer-column {
	height: 100%;
	padding-top: 30px;
}

.footer-text {
	padding: 10px 10px 10px 0px;
}

@media screen and (max-width: 600px) {
	.footer-home {
		grid-template-columns: 3fr 2fr;
		padding-left: 28px;
		padding-bottom: 20px;
		height: 220px;
	}

	.hide-mobile {
		display: none;
	}
}

@media screen and (min-width: 601px) {
	.hide-desktop {
		display: none;
	}
}

@media screen and (min-width: 1000px) {
	.footer-home {
		grid-template-columns: 2fr 1fr;
		height: 180px;
	}
}
