.contact-container {
	width: 100%;
	overflow-x: hidden;
	height: 100%;
	background-color: white;
}

p {
	margin-top: 0px;
}

.info {
	display: grid;
	grid-template-columns: 1fr 3fr 1fr;
	width: 100%;
	background-color: white;
	max-height: calc(100vh - 79px);
	overflow: auto;
}

.info a {
	color: black;
}

.info-label {
	padding: 28px;
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 19px;
}

.info-label-about {
	padding: 28px 0px 0px 28px;
}

.info-label-services {
	padding: 8px 0px 0px 28px;
}

.info-text {
	border-bottom: 1px solid black;
	margin-left: 10px;
	padding-top: 28px;
	margin-bottom: 20px;

	font-style: normal;
	font-weight: 400;
	font-size: 20px;
	line-height: 27px;
}

.info-text-contact {
	border-bottom: 1px solid black;
	margin-left: 10px;
	padding-top: 28px;

	font-style: normal;
	font-weight: 400;
	font-size: 20px;
	line-height: 27px;
}

.info-column-container {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	font-size: 14px;
	margin-left: 10px;
	padding-top: 8px;
	border-bottom: 1px solid black;
	margin-bottom: 20px;
}

.info-column {
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 23px;
}

.info-spacer {
	border-bottom: 1px solid black;
	padding-bottom: 20px;
	margin-bottom: 20px;
}

.info-spacer-contact {
	border-bottom: 1px solid black;
	padding-bottom: 20px;
}

@media screen and (max-width: 600px) {

	.contact {
		overflow: hidden;
	}

	.about {
		overflow-y: auto;
		margin-bottom: 200px;
		max-height: calc(100vh - 79px);
	}

	.info-label-about {
		margin-bottom: 13px;
	}

	.info {
		grid-template-columns: 1fr;
	}

	.info-column-container {
		grid-template-columns: 1fr;
		margin-left: 28px;
	}
	.info-spacer {
		display: none;
	}

	.info-spacer-contact {
		display: none;
	}

	.info-text {
		margin-left: 28px;
		padding-bottom: 28px;
		padding: 0px;
	}

	.info-column {
		padding-top: 0px;
		font-size: 20px;
		margin-bottom: 0px;
	}


	.info-text > p {
		padding-right: 28px;
	}

	.info-text-contact > p {
		padding-right: 28px;
	}

	.info-label {
		padding-bottom: 0px;
	}

	.info-text-contact {
		padding: 0px;
		margin-left: 28px;
		padding-bottom: 28px;
	}

}


@media screen and (min-width: 600px) and (max-width: 980px) {
	.info {
		grid-template-columns: 1fr 3fr;
	}
	.info-column-container {
		grid-template-columns: 1fr 1fr 1fr;
	}

	.info-spacer {
		display: none;
	}

	.info-spacer-contact {
		display: none;
	}
}