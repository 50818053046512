.home-parent {
	background-color: black;
	overflow-y: auto;
	overflow-x: hidden;
	position: relative;
	margin-top: 46px;
	height: calc(100vh - 100px);
	overscroll-behavior: contain;
}

.home-container {
	background-color: black;
	overflow-x: contain;
	width: 100%;
	position: relative;
}

.home-media-container {
	margin-left: 115px;
	position: relative;
	height: fit-content;
	padding-bottom: 10px;
}

.home-media {
	width: 100%;
	max-width: 95%;
	margin-top: -352px;
}

.home-media-label {
	position: -webkit-sticky; 
	position: sticky;
	color: white;
	top: 20%;
	bottom: 50px;
	padding-left: 20px;
	padding-bottom: 30px;
	padding-top: 300px;
}

.home-media-label-mobile {
	display: none;
}

@media screen and (max-width: 900px) {
	.home-parent {
		flex-direction: column;
	}
	.home-media-container {
		margin-left: 40px;
	}
}

@media screen and (max-width: 600px){
	.home-media-label {
		display: none;
	}
	
	.home-media-label-mobile {
		padding-top: 5px;
		padding-bottom: 15px;
		padding-left: 0px;
		color: white;
		display: block;
	}

	.home-media {
		margin-top: 0px;
		position: relative;
	}

	.home-media-container {
		margin-left: 20px;
	}

	.home-container {
		margin-right: 20px;
		max-width: 100vw;
	}

	.home-parent {
		padding-top: 0px;
		margin-top: 0px;
		height: calc(100vh - 79px);

	}

}