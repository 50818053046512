@font-face {
    font-family: 'Helvetica Neue LT Pro';
    src: url('HelveticaNeueLTPro-Roman.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

html, body {
	margin: 0;
	height: 100vh;
	font-family: 'Helvetica Neue LT Pro', 'Helvetica Neue', 'Helvetica', sans-serif;
	display: flex;
	min-height: 100vh;
	flex-direction: column;
	justify-content: space-between;
	overscroll-behavior: none;
	overflow: hidden;
}

#root {
	min-height: 100%;
}

.content {
	padding-top: 79px;
	display: flex;
	flex: 1;
	background-color: black;
}

.App {
	min-height: 100%;
	display: flex;
	flex-direction: column;
}

a {
	text-decoration: none;
}