.hero-container {
	width: 100%;
	display: flex;
}

.hero-image, .hero-video {
	width: 100%;
}

.hero-caption {
	align-self: flex-end;
	line-height: 24px;
	font-size: 20px;
	padding: 20px;
	font-style: normal;
	font-weight: 400;
	font-size: 20px;
	line-height: 24px;
}
