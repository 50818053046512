header {
	display: flex;
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 19px;
	position: fixed;
	top: 0;
	width: 100%;
}

header > nav {
	display: flex;
	margin-left: 28px;
	border-bottom: 1px solid black;
	justify-content: space-between;
	width: 100%;

}

.nav-home {
	min-width: 200px;
	padding-top: 30px;
}

.nav-menu {
	display: flex;
	justify-content: flex-end;
	width: 100%;
	padding: 0 20px;
}

.white-bg {
	background-color: white;
	color: black;
}

.black-bg {
	color: white;
	background-color: black;
	border-bottom: none;
}

.black-bg .burger-line {
	background-color: white;
}

.white-bg .burger-line {
	background-color: black;
}

.white-bg a {
	color: black;
}

.black-bg a {
	color: white;
}

.header-home {
	height: 125px;
}

.header-small {
	height: 79px;
}

.nav-menu-mobile {
	display: none;
}

.nav-menu-mobile-leave {
	height: 20px;
	width: 25px;
	display: flex;
	padding: 20px 5px 20px 20px;
	cursor: pointer;
}


.x-line {
	height: 25px;
	margin: 4px 0px;
	border-right: 1px solid black;
}

.x-line-right {
	transform: rotate(45deg);
}

.x-line-left {
	transform: rotate(-45deg);
}


@media screen and (max-width: 600px) {
	.menu {
		display: contents;
		z-index: 100;
		position: absolute;
		right: 0;
		top: 0;
		width: 100vw;
		height: 100vh;
		background-color: white;
		color: black;

	}

	.nav-home {
		border: none;
	}

	.menu-top {
		margin-left: 10px;
	}

	.nav-menu {
		display: none;
	}

	.nav-menu-mobile {
		display: block;
		padding: 22px;
	}

	.burger-line {
		width: 20px;
		height: 1px;
		margin: 8px 0;
	}
}
