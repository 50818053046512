.work-container {
	width: 100%;
	background-color: white;
	height: calc(100vh - 78px);
	overflow-y: auto;
}

.work-text-container {
	display: grid;
	grid-template-columns: 1fr 2fr 1fr;
	grid-gap: 100px;
	border-bottom: 1px solid black;
	padding-bottom: 30px;
	border-top: 19px solid black;
	margin-left: 30px;
	padding-top: 30px;
	margin-bottom: 30px;
}

.work-work-container .hero-container {
	padding-bottom: 10px;
}

.work-work-container .hero-container:last-child {
	padding-bottom: 0px;
}

.mobile-only {
	display: none;
}

.work-project-title {
	font-size: 8em;
	border-top: 1px solid black;
	border-bottom: 1px solid black;
	margin: 20px 0px 20px 30px;
	padding-top: .15em;
	padding-bottom: .1em;
	letter-spacing: -0.03em;
}


.work-smalltext {
	font-size: 14px;
}

.work-description {
	font-size: 20px;
}

.work-title-part {
	margin-bottom: -.2em;
}

@media screen and (max-width: 1000px) {
	.work-project-title {
		font-size: 5em;
	}

}

@media screen and (max-width: 600px) {

	.work-project-title {
		font-size: 2em;
	}

	.work-text-container {
		grid-template-columns: 1fr;
		border-top: 0px;
		padding-top: 0px;
		border-bottom: 0;
		grid-gap: 20px;
		margin-bottom: 0px;
	}

	.work-description {
		border-top: 1px solid black;
		padding-top: 30px;
		border-bottom: 1px solid black;
		padding-bottom: 20px;
		padding-right: 28px;

	}

	.work-client-tag {
		border-top: 29px solid black;
		padding-top: 30px;
	}

	.mobile-only {
		display: block;
	}

	.work-services {
		border-bottom: 1px solid black;
		padding-bottom: 20px;
	}

	.work-work-container .hero-container {
		padding-bottom: 5px;
	}
	
}