.work-footer {
	background-color: black;
	color: white;
	height: 79px;
	display: flex;
	align-items: center;
	padding-left: 30px;
	font-size: 14px;
}

.work-footer > a {
	color: white;
	margin-left: 28px;
	padding-right: 100px;
}


@media screen and (max-width: 600px) {
	.work-footer {
		padding-bottom: 20px;
		height: 220px;
	}

	.work-footer > a {
		margin-left: unset;
		padding-right: 35px;
	}
}
