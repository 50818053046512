.menu-main {
	background-color: white;
	width: 100%;
}

.menu-main > a {
	color: black;
	font-size: 20px;
}

.menu-header {
	padding: 28px;
}

@media screen and (max-width: 600px) {
	.menu {
		display: contents;
		z-index: 100;
		position: absolute;
		right: 0;
		top: 0;
		width: 100vw;
		height: 100vh;
		background-color: white;
		color: black;
	}

	.menu-item-link {
		margin-left: 28px;
		margin-bottom: -22px;
	}
}