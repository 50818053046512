@font-face {
    font-family: 'Helvetica Neue Bold';
    src: url('../HelveticaNeueLTPro-Bd.woff2') format('woff2');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

.spike {
	position: fixed;
	width: 734px;
	left: 51px;
	top: -30px;
	
	font-family: 'Helvetica Neue Bold';
	font-style: normal;
	font-weight: 700;
	font-size: 200px;
	line-height: 305px;
	letter-spacing: -0.06em;
	color: white;
	z-index: 1000;
}

.spike-plus-s {
	padding-right: .01em;
}
.spike-plus-k {
	margin-left: -.035em;
}
.spike-plus-e {
	margin-left: -.035em;
	margin-right: .1em;
}

@media screen and (max-width: 600px) {
	.spike {
		font-size:60px;
		width: 250px;
		top: -110px;
		left: 10px;
		height: 100px;
	}
}


@media screen and (min-width: 600px) and (max-width: 980px) {
	.spike {
		font-size: 130px;
		width: 500px;
		top: -29px;
		left: 20px;
	}
}